
export const setLoading = (state) => {
    state.isLoading = false
}
export const actLoading = (state) => {
    state.isLoading = true
}

export const clearEventos = (state) => {
    state.eventos = []
    // state.eventoscategorias = []
}

export const setEventos = (state, eventos) => {
    state.eventos = [...state.eventos, ...eventos]
    state.isLoading = false
}
export const setEventosCategorias = (state, eventos) => {
    state.eventoscategorias = [...eventos]
    // state.categorias = eventos.categorias
    state.isLoading = false
}
export const setEvento = (state, evento) => {
    state.selectedEvento = evento
    state.isLoading = false
}
export const setEventoCategoriasAtletas = (state, evento) => {
    if (evento.status) {
        state.page404 = true;
    } else {
        const event = evento.evento
        const atletas = evento.atletas
        const categorias = evento.evento.categorias
        state.search = ''
        state.selectedEvento = event
        state.atletas = atletas
        state.categorias = categorias
        state.isLoading = false
    }
}
export const setEventoCategoriasAtletasSexo = (state, evento) => {
    if (evento.status) {
        state.page404 = true;
    } else {

        const event = evento.evento
        const atletas = evento.atletas
        const categorias = evento.evento.categorias
        state.search = ''
        state.selectedEvento = event
        state.atletas = atletas
        state.categorias = categorias
        state.isLoading = false
    }
}
export const setConfig = (state, config) => {
    state.config = config
    state.isLoading = false
}

export const setEventoCategoria = (state, slug) => {

}
export const setSearchName = (state, term) => {
    state.search = term
}


//LOCAL

export const mostrarPorCategoriaDistancia = (state, res) => {
    state.countCat = res.length
    if (res != 0) {
        if (state.categoriasReserva.length > 0) {
            if (state.countCatReserva.length == state.countCat) {
                state.categoriasReserva = state.categorias
                state.categorias = res
            } else {
                state.categorias = res
            }
        } else {
            state.categoriasReserva = state.categorias
            state.categorias = res
        }
    } else {
        state.atletas = state.categoriasReserva
    }
}

export const mostrarAtletasPorCategoriaSexo = (state, result) => {
    state.countAtletas = result.length
    if (result != 0) {
        if (state.atletasReserva.length > 0) {
            if (state.countReserva.length == state.countAtletas) {
                state.atletasReserva = state.atletas
                state.atletas = result
            } else {
                state.atletas = result
            }
        } else {
            state.atletasReserva = state.atletas
            state.atletas = result
        }
    } else {
        state.atletas = state.atletasReserva
    }


    /** 
        if (state.countReserva.length == state.countAtletas) {
            state.atletasReserva = state.atletas
            state.atletas = result
        }else{
            state.atletas = result
        }
    
    
        if (result != '') {
            if (state.countReserva.length == state.countAtletas) {
                state.atletasReserva = state.atletas
                state.atletas = result
            } else {
                state.atletas = result
            }
            / *} else if (result == 0) {
                state.atletas = result
                setTimeout(() => {
                    //state.atletas = []
                }, 300)
        } else {
    
    
            state.atletas = state.atletasReserva
        }*/
}

/*
export const mostrarAtletasPorCategoriaSexo = (state, result) => {
    if (result == 10) {
        if (state.atletas.length > 0) {
            // state.atletas = [...result]
            state.atletasReserva = state.atletas
        } else if (state.atletasReserva.length > 0) {
            state.atletas = [...state.atletasReserva]
        } else {
        }
        // state.atletas = [...state.atletasReserva]
    } else if (result != '') {
        state.atletas = [...result]
        state.atletasReserva = state.atletas
    } else if (result == 0) {
        state.atletas = result
        setTimeout(() => {
            //state.atletas = state.atletasReserva
        }, 300)
    } else {
        state.atletas = state.atletasReserva
    }
}
*/