import { event } from "jquery"

export const gettersEventos = (state) => {
    return state.eventos
}
export const getLoading = (state) => {
    return state.isLoading
}
export const gettersEventosCategorias = (state) => {
    return state.eventoscategorias
}

export const getterEvento = (state) => {
    return state.selectedEvento
}
export const getterAtletas = (state) => {
    return state.atletas
}
export const getterAtletasReserva = (state) => {
    return state.atletasReserva
}
export const getterCategorias = (state) => {
    return state.categorias
}
export const getterCategoriasReserva = (state) => {
    return state.categoriasReserva
}
export const page404 = (state) => { return state.page404 }

export const gettersEventosSplice = (state) => {
    const event = [...state.eventos]
    return event.splice(0, 6)
}

export const getConfigHeader = (state) => {
    return state.config.config.header
}
export const getConfigSlider = (state) => {
    return state.config.config
}

export const getAtletasByTerm = (state) => (search = '') => {
    if (search.length === 0) return state.atletas
    //if(state.atletas.length < state.atletasReserva.length){  }
    var atletas = []
    if (search.length > 0) {
        for (let i = 0; i < state.atletas.length; i++) {
            if (state.atletas[i].nombres.toLowerCase().includes(search.toLowerCase())) {
                atletas.push(state.atletas[i])
            } else if (search.toString().toLowerCase().includes('k')) {
                if (state.atletas[i].distancia.includes(search.toString().toLowerCase())) {
                    atletas.push(state.atletas[i])
                }
            } else if (!isNaN(search)) {
                if (search.length <= 3) {
                    if (state.atletas[i].numero.includes(search.toString())) {
                        atletas.push(state.atletas[i])
                    }
                } else if (state.atletas[i].cedula.includes(search)) {
                    atletas.push(state.atletas[i])
                }
            } else {
                //atletas.push(state.atletas[i])
            }
        }
        if (atletas.length > 0) {
            return atletas
        } else {
            return []
        }
    }
}

export const getBusEvento = (state) => (search = '') => {
    if (search.length === 0) return state.eventos;
    let eventos = []
    if (search.length > 0) {
        for (let i = 0; i < state.eventos.length; i++) {
            if (state.eventos[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                eventos.push(state.eventos[i])
            }
        }
        if (eventos.length > 0) {
            return eventos
        } else {
            return []
        }
    }
}
export const getBusId = (state) => (id) => {
    const evento = state.eventos.find(evento => evento.id == id)
    if (!evento) return
    return { ...evento }
}
